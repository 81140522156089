import React from 'react';
import useTheme from '@mui/styles/useTheme';
import { isProduction } from '@practice_users/services/locationHelper';

const getRawCSS = (theme) => {
  const { palette } = theme;
  return `
    html, body {
      font-family: ${theme.typography.fontFamily};
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      height: 100%;
    }
    body {
      width: calc(100% - 32px);
    }
    .error {
      color: ${palette.error.main};
      color: red;
      font-size: 12px;
      margin-top: 4px;
      border-color: red;
    }
    label {
      display: block;
      margin-bottom: 8px;
      margin-top: 16px;
    }
    input {
      width: 100%;
    }
    input#cccvvfield {
      width: 36px;
    }
    input,select{
      font-size: 16px;
      padding: 16px 14px;
      border-radius: 4px;
      border-width: 1px;
      border-style: solid;
      border-color: ${palette.grey[400]};
    }
    select:focus, input:focus {
      border-color: ${palette.primary.main};
      outline: none;
    }
    br {
      display: none;
    }
  `;
};

export default function IframeCreditCardTokenizer({ processToken }) {
  const theme = useTheme();
  const rawCSS = getRawCSS(theme);
  const iframeTokenizerSite = `https://${isProduction ? 'isv' : 'isv-uat'}.cardconnect.com`;
  const tokenizerParameters = new URLSearchParams(
    {
      autofocus: true,
      cardnumbernumericonly: true,
      fullmobilekeyboard: true,
      tokenizewheninactive: true,
      inactivityto: 500,
      useexpiry: true,
      usecvv: true,
      invalidcreditcardevent: true,
      formatinput: true,
    },
  ).toString();
  const tokenizerCss = `css=${encodeURIComponent(rawCSS)}`;
  const iframeTokenizerUrl = `${iframeTokenizerSite}/itoke/ajax-tokenizer.html?${tokenizerParameters}&${tokenizerCss}`;

  const onMessage = (event) => {
    if (event.origin !== iframeTokenizerSite) return;

    const data = JSON.parse(event.data);
    const { message, expiry, validationError } = data;

    processToken(message, expiry, validationError);
  };

  React.useEffect(() => {
    window.addEventListener('message', onMessage);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <iframe
      src={iframeTokenizerUrl}
      title="credit-card-tokenizer"
      name="credit-card-tokenizer"
      style={{ height: '289px', border: 'none', width: '100%' }}
    />
  );
}
