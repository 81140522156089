/* eslint-disable max-len */
/* eslint-disable filenames/match-exported */
const locationData = document.getElementById('locationData');
const globalVars = locationData ? JSON.parse(locationData.innerHTML) : {};

const hasLocationVars = () => globalVars && globalVars.location;
export const currentLocation = {
  activeProducts: hasLocationVars() && globalVars.location.activeProducts,
  appointmentConfirmation: hasLocationVars() && globalVars.location.appointmentConfirmation,
  appointmentReminder: hasLocationVars() && globalVars.location.appointmentReminder,
  appointmentWidgetLastClicked: hasLocationVars() && globalVars.location.appointmentWidgetLastClicked,
  appointmentsCount: hasLocationVars() && globalVars.location.appointmentsCount,
  autoResponder: hasLocationVars() && globalVars.location.autoResponder,
  billingStartDay: hasLocationVars() && globalVars.location.billingStartDay,
  brand: hasLocationVars() && globalVars.location.brand,
  brSmsEnabled: hasLocationVars() && globalVars.location.brSmsEnabled,
  businessInfo: hasLocationVars() && globalVars.location.businessInfo,
  canCancelAppointments: hasLocationVars() && globalVars.location.canCancelAppointments,
  campaigns: hasLocationVars() && globalVars.location.campaigns,
  campaignsCsvUploadLimit: hasLocationVars() && globalVars.location.campaignsCsvUploadLimit,
  campaignsEligible: hasLocationVars() && globalVars.location.campaignsEligible,
  campaignsPatientGroups: hasLocationVars() && globalVars.location.campaignsPatientGroups,
  campaignsSelectFromSchedule: hasLocationVars() && globalVars.location.campaignsSelectFromSchedule,
  customTemplates: hasLocationVars() && globalVars.location.customTemplates,
  demo: hasLocationVars() && globalVars.location.demo,
  smsDisplayName: hasLocationVars() && globalVars.location.smsDisplayName,
  shortName: hasLocationVars() && globalVars.location.shortName,
  address1: hasLocationVars() && globalVars.location.address1,
  address2: hasLocationVars() && globalVars.location.address2,
  city: hasLocationVars() && globalVars.location.city,
  state: hasLocationVars() && globalVars.location.state,
  country: hasLocationVars() && globalVars.location.country,
  zip: hasLocationVars() && globalVars.location.zip,
  fullAddress: hasLocationVars() && globalVars.location.fullAddress,
  hasConfirmationSchedules: hasLocationVars() && globalVars.location.hasConfirmationSchedules,
  hasPatientFinancingUrl: hasLocationVars() && globalVars.location.hasPatientFinancingUrl,
  hasWebsiteMessengerUrl: hasLocationVars() && globalVars.location.hasWebsiteMessengerUrl,
  id: hasLocationVars() && globalVars.location.id,
  intakeFormInTemplates: hasLocationVars() && globalVars.location.intakeFormInTemplates,
  intakeShowInsurances: hasLocationVars() && globalVars.location.intakeShowInsurances,
  images: hasLocationVars() && globalVars.location.images,
  integrated: hasLocationVars() && globalVars.location.integrated,
  integratedAnalytics: hasLocationVars() && globalVars.location.integratedAnalytics,
  integrationStatus: hasLocationVars() && globalVars.location.integrationStatus,
  languagePreferenceId: hasLocationVars() && globalVars.location.languagePreferenceId,
  locationPage: hasLocationVars() && globalVars.location.locationPage,
  magicLink: hasLocationVars() && globalVars.location.magicLink,
  messagingPhone: hasLocationVars() && (globalVars.location.messagingPhone || {}),
  missedNoticeAutomated: hasLocationVars() && globalVars.location.missedNoticeAutomated,
  missedNotices: hasLocationVars() && globalVars.location.missedNotices,
  missedNoticesActive: hasLocationVars() && globalVars.location.missedNoticesActive,
  name: hasLocationVars() && globalVars.location.name,
  oldestAppointmentTime: hasLocationVars() && globalVars.location.oldestAppointmentTime,
  onboarding: hasLocationVars() && globalVars.location.onboarding,
  patientCommunicator: hasLocationVars() && globalVars.location.patientCommunicator,
  patientGroups: hasLocationVars() && globalVars.location.patientGroups,
  patientInsight: hasLocationVars() && globalVars.location.patientInsight,
  paymentCollector: hasLocationVars() && globalVars.location.paymentCollector,
  pcAwayResponder: hasLocationVars() && globalVars.location.pcAwayResponder,
  pcAwayResponderLive: hasLocationVars() && globalVars.location.pcAwayResponderLive,
  pcFocusedInbox: hasLocationVars() && globalVars.location.pcFocusedInbox,
  pcSendImages: hasLocationVars() && globalVars.location.pcSendImages,
  pcBulkMessage: hasLocationVars() && globalVars.location.pcBulkMessage,
  pcBulkMessageLimit: hasLocationVars() && globalVars.location.pcBulkMessageLimit,
  pcContactUploaderLimit: hasLocationVars() && globalVars.location.pcContactUploaderLimit,
  pcMessageLengthLimit: hasLocationVars() && globalVars.location.pcMessageLengthLimit,
  pcContactUploader: hasLocationVars() && globalVars.location.pcContactUploader,
  pcPatientGroups: hasLocationVars() && globalVars.location.pcPatientGroups,
  pcSelectFromSchedule: hasLocationVars() && globalVars.location.pcSelectFromSchedule,
  pcUnreadEmailInterval: hasLocationVars() && globalVars.location.pcUnreadEmailInterval,
  pcScheduleMessage: hasLocationVars() && globalVars.location.pcScheduleMessage,
  pcScheduleBroadcast: hasLocationVars() && globalVars.location.pcScheduleBroadcast,
  pcTranslationAutomated: hasLocationVars() && globalVars.location.pcTranslationAutomated,
  phoneNumberChanged: hasLocationVars() && globalVars.location.phoneNumberChanged,
  prOrderReminder: hasLocationVars() && globalVars.location.prOrderReminder,
  pmsName: hasLocationVars() && globalVars.location.pmsName,
  pmsVersion: hasLocationVars() && globalVars.location.pmsVersion,
  prArrivalTime: hasLocationVars() && globalVars.location.prArrivalTime,
  prArrivalTimeMinutesBefore: hasLocationVars() && globalVars.location.prArrivalTimeMinutesBefore,
  prMapsUrlEnabled: hasLocationVars() && globalVars.location.prMapsUrlEnabled,
  prMapsUrl: hasLocationVars() && globalVars.location.prMapsUrl,
  prSaveTheDate: hasLocationVars() && globalVars.location.prSaveTheDate,
  practiceName: hasLocationVars() && globalVars.location.practiceName,
  practiceUsers: hasLocationVars() && globalVars.location.practiceUsers,
  preferredContactMethod: hasLocationVars() && globalVars.location.preferredContactMethod,
  prettyPhone: hasLocationVars() && globalVars.location.prettyPhone,
  primaryPhone: hasLocationVars() && globalVars.location.primaryPhone,
  providerCanCreateSchedule: hasLocationVars() && globalVars.location.providerCanCreateSchedule,
  productStatuses: hasLocationVars() && globalVars.location.productStatus,
  recall: hasLocationVars() && globalVars.location.recall,
  recallAutomated: hasLocationVars() && globalVars.location.recallAutomated,
  recallReactivation: hasLocationVars() && globalVars.location.recallReactivation,
  recallUseTreatments: hasLocationVars() && globalVars.location.recallUseTreatments,
  recallFilterTreatments: hasLocationVars() && globalVars.location.recallFilterTreatments,
  recentSentPeriod: hasLocationVars() && globalVars.location.recentSentPeriod,
  reviewMgnt: hasLocationVars() && globalVars.location.reviewMgnt,
  reviewRequestAutomation: hasLocationVars() && globalVars.location.reviewRequestAutomation,
  reviewRequestFilter: hasLocationVars() && globalVars.location.reviewRequestFilter,
  reviewRequestUploader: hasLocationVars() && globalVars.location.reviewRequestUploader,
  rmDisableFeedback: hasLocationVars() && globalVars.location.rmDisableFeedback,
  rmDisableReminder: hasLocationVars() && globalVars.location.rmDisableReminder,
  rmFacebookAutoPublish: hasLocationVars() && globalVars.location.rmFacebookAutoPublish,
  rmGoogleAutoReply: hasLocationVars() && globalVars.location.rmGoogleAutoReply,
  reviewRequestScheduleSms: hasLocationVars() && globalVars.location.reviewRequestScheduleSms,
  reviewRequestScheduleEmail: hasLocationVars() && globalVars.location.reviewRequestScheduleEmail,
  roles: hasLocationVars() && globalVars.location.roles,
  showManageProviders: hasLocationVars() && globalVars.location.showManageProviders,
  showLocationName: hasLocationVars() && globalVars.location.showLocationName,
  showLogo: hasLocationVars() && globalVars.location.showLogo,
  smsKeywords: hasLocationVars() && globalVars.location.smsKeywords,
  settings: hasLocationVars() && globalVars.location.settings,
  ssWritebackEnabled: hasLocationVars() && globalVars.location.ssWritebackEnabled,
  ssBookingPage: hasLocationVars() && globalVars.location.ssBookingPage,
  ssBookingsettingsPage: hasLocationVars() && globalVars.location.ssBookingsettingsPage,
  ssCreateAppointment: hasLocationVars() && globalVars.location.ssCreateAppointment,
  ssCreatePatient: hasLocationVars() && globalVars.location.ssCreatePatient,
  textTranslation: hasLocationVars() && globalVars.location.textTranslation,
  uniqueId: hasLocationVars() && globalVars.location.uniqueId,
  timeZone: hasLocationVars() && globalVars.location.timeZone,
  voiceDropper: hasLocationVars() && globalVars.location.voiceDropper,
  volume: hasLocationVars() && globalVars.location.volume,
  vrMessageLengthLimit: hasLocationVars() && globalVars.location.vrMessageLengthLimit,
  vwr: hasLocationVars() && globalVars.location.vwr,
  websiteMessenger: hasLocationVars() && globalVars.location.websiteMessenger,
  websiteMessengerUrl: hasLocationVars() && globalVars.location.websiteMessengerUrl,
  websiteType: hasLocationVars() && globalVars.location.websiteType,
  websiteUrl: hasLocationVars() && globalVars.location.websiteUrl,
  canToggleToClassicView: hasLocationVars() && globalVars.location.canToggleToClassicView,
  canOptOutFromSettingsGroup: hasLocationVars() && globalVars.location.canOptOutFromSettingsGroup,
  settingsManagedByEnterprise: hasLocationVars() && globalVars.location.settingsManagedByEnterprise,
  optOutFromSettingsGroup: hasLocationVars() && globalVars.location.optOutFromSettingsGroup,
  easyFill: hasLocationVars() && globalVars.location.easyFill,
  efAsapList: hasLocationVars() && globalVars.location.efAsapList,
  efCreateAppt: hasLocationVars() && globalVars.location.efCreateAppt,
  efNotifyDenial: hasLocationVars() && globalVars.location.efNotifyDenial,
  efFastFill: hasLocationVars() && globalVars.location.efFastFill,
  efNotifyCancellation: hasLocationVars() && globalVars.location.efNotifyCancellation,
  efCancellationRangeMin: hasLocationVars() && globalVars.location.efCancellationRangeMin,
  efCancellationRangeMax: hasLocationVars() && globalVars.location.efCancellationRangeMax,
  efWritebackEnabled: hasLocationVars() && globalVars.location.efWritebackEnabled,
  efPatientGroupEnabled: hasLocationVars() && globalVars.location.efPatientGroupEnabled,
  efPatientGroupRule: hasLocationVars() && globalVars.location.efPatientGroupRule,
  efPatientGroupId: hasLocationVars() && globalVars.location.efPatientGroupId,
  formsWriteback: hasLocationVars() && globalVars.location.formsWriteback,
  formsCreatePatient: hasLocationVars() && globalVars.location.formsCreatePatient,
  formsDemographicUpdate: hasLocationVars() && globalVars.location.formsDemographicUpdate,
  formsInsuranceUpdate: hasLocationVars() && globalVars.location.formsInsuranceUpdate,
  formsMedicalHistory: hasLocationVars() && globalVars.location.formsMedicalHistory,
  formsMedicalHistoryAcknowledgement: hasLocationVars() && globalVars.location.formsMedicalHistoryAcknowledgement,
  formsReview: hasLocationVars() && globalVars.location.formsReview,
  verifiedBusinessInfo: hasLocationVars() && globalVars.location.verifiedBusinessInfo,
  tenDlcBrandRegistration: hasLocationVars() && globalVars.location.tenDlcBrandRegistration,
  tenDlcCampaignStatus: hasLocationVars() && globalVars.location.tenDlcCampaignStatus,
  nexhealth: hasLocationVars() && globalVars.location.nexhealth,
  logo: hasLocationVars() && globalVars.location.logo,
  lastSurveySparrowAt: hasLocationVars() && globalVars.location.lastSurveySparrowAt,
  newDashboard: hasLocationVars() && globalVars.location.newDashboard,
  usingFromToSendSms: hasLocationVars() && globalVars.location.usingFromToSendSms,
  canWriteback: hasLocationVars() && globalVars.location.canWriteback,
  manualPatientSyncAllowed: hasLocationVars() && globalVars.location.manualPatientSyncAllowed,
  createdAt: hasLocationVars() && globalVars.location.createdAt,
  payments: hasLocationVars() && globalVars.location.payments,
};
const hasPracticeUserVars = () => globalVars && globalVars.practiceUser;
export const currentPracticeUser = {
  locations: hasPracticeUserVars() && globalVars.practiceUser.locations,
  email: hasPracticeUserVars() && globalVars.practiceUser.email,
  permissions: hasPracticeUserVars() && globalVars.practiceUser.permissions,
  roleId: hasPracticeUserVars() && globalVars.practiceUser.roleId,
  roleName: hasPracticeUserVars() && globalVars.practiceUser.roleName,
  presentInAllLocations: hasPracticeUserVars() && globalVars.practiceUser.presentInAllLocations,
  id: hasPracticeUserVars() && globalVars.practiceUser.id,
  isTrueUser: hasPracticeUserVars() && globalVars.practiceUser.isTrueUser,
  isEnterpriseUser: hasPracticeUserVars() && globalVars.practiceUser.isEnterpriseUser,
  fullName: hasPracticeUserVars() && globalVars.practiceUser.fullName,
  firstName: hasPracticeUserVars() && globalVars.practiceUser.firstName,
  lastName: hasPracticeUserVars() && globalVars.practiceUser.lastName,
  darkTheme: hasPracticeUserVars() && globalVars.practiceUser.darkTheme,
  pcNotificationSound: hasPracticeUserVars() && globalVars.practiceUser.pcNotificationSound,
  showResyncNeededNotification: hasPracticeUserVars() && globalVars.practiceUser.showResyncNeededNotification,
  tollfreeRegistrationFailures: hasPracticeUserVars() && globalVars.practiceUser.tollfreeRegistrationFailures,
  homePath: hasPracticeUserVars() && globalVars.practiceUser.homePath,
  mangoExtension: hasPracticeUserVars() && globalVars.practiceUser.mangoExtension,
};
export const isProduction = globalVars && globalVars.isProduction;
export const isAdmin = globalVars && globalVars.isAdmin;
export const daysInMonth = globalVars && globalVars.daysInMonth;
export const enforcePcLimit = globalVars && globalVars.enforcePcLimit;
export const missedAppointmentsForAutomatedCustomers = globalVars && globalVars.missedAppointmentsForAutomatedCustomers;
export const businessInfoCollectionLimitDate = globalVars && globalVars.businessInfoCollectionLimitDate;
export const intakeIframeMode = globalVars && globalVars.intakeIframeMode;
export default currentLocation;
